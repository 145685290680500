
@font-face {
  font-family: Paragraph;
  src: url('./fonts/theFont.otf');
  /* src: url('/theFont.otf') format('opentype'); */
  
}

html{
  font-size: 1vw;
  background-color:rgb(229,255,204);
}

.App{
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-family: Paragraph;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav{
  width: 20vw;
  display:flex;
  justify-content: space-between;
}

img{
  height: 20rem;
}

.backIcon{
  height:6rem;
}
.navContainer{
  width: 100vw;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

a{
  text-transform: lowercase;
  text-decoration: none;
  font-size: 2rem;
  color:black;
  opacity: 0.5;
}

a:hover{
  color:black;
  opacity: 1;
  transition: 0.2s;
  
}
.titleContainer{  
  height:95vh;
  width: 100vw;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  white-space: nowrap;
}

.recentLinks{
  display: flex;
  flex-direction: row;
}

.titleBox{  
  /* border-style: dashed; */
  
  /* height: 15rem; */
  /* height: 20rem; */
  height: 30rem;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

h1{  
  font-size: 8.1rem;
  pointer-events: none;
  color:black;
  font-family: Paragraph;
  margin:0;
  text-transform: lowercase;
  /* cursor: grab; */
}

p{  
  font-size: 2rem;
  color:black;
  text-transform: lowercase;
  margin:0;
}

.formContainer{
  display:flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}

input:focus::placeholder, textarea:focus::placeholder {
  color: transparent;
}

.theForm{  
  font-size: 1.5rem;
  width: 20vw;
  height: 25rem;
  display:flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.messageBox{
  height:13rem;
}
.success{
  left:0;
  top:0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(229,255,204);
  /* padding-top: 5rem; */
  text-align: center; 
  justify-content: center;
  position: absolute; 
  display:flex;
  flex-direction: column;
  align-items: center;
  
  /* border-style: dotted; */
}

.theParas{
  padding-bottom: 1rem;
}
textarea {
  resize: none;
}

.form-control{
  width: 20vw;
  /* height: 2rem; */
  margin-left: 1vw;
  font-family: inherit;
  font-size: inherit;
  border-radius: 1px;
  border-style: solid;
  border-color: white;
}

.notMsg{
  height: 2rem;
}

.form-group{  
  display:flex;
  flex-direction: column;
}

label{  
  align-self: flex-start;
}

.submitButton{
  width: 20.5vw;
  /* height: 5vh; */
  border-radius: 1px;
  border-color: black;
  border-style: solid;
  background-color: black;
  color: aliceblue;
  font-size: inherit;
  font-family: inherit;
  text-transform: lowercase;
  text-align: center;
  padding-top:0.7rem;
  padding-bottom:0.5rem;
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  html{
    font-size: 1.5vw;
  }

 
}


@media only screen and (max-width: 563px){

  .notMsg{
    height: 5rem;
  }
  .form-control{
    width: 90vw;
    
  }

  .theForm{
    width: 90vw;
    font-size: 5rem;
    height: 70vh;
  }

  .submitButton{
    width: 90vw;
  }

  p{
    font-size: 3.5rem;

  }

  a{
    font-size: 3.5rem;
  }

  .titleBox{
    height:50rem;
  }

  .form-control{
    font-size: 3.5rem;
    
  }
  .messageBox{
    height:42vh;
  }
  .submitButton{
    font-size: 3.5rem;
    
  }
}
@media only screen and (max-width: 500px) {
  html{
    font-size: 1.9vw;
  }
}

@media (orientation: portrait){

  .recentLinks{
    flex-direction: column;
  }
}

.recentLinkImage{
  opacity: 1;
}
.recentLinkImage:hover{
  opacity:0.7;
}